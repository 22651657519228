import { Dayjs } from 'dayjs';

export enum EVENTS {
  LOGIN_BONUS_2501 = 'login_bonus_2501',
  LOGIN_BONUS_2502 = 'login_bonus_2502',
  MOON_GREETING_240914 = 'moon_greeting_240914',
  MOON_GREETING_240917 = 'moon_greeting_240917',
  MOON_GREETING_240920 = 'moon_greeting_240920',
  SKETCHUP_PROMOTION = 'sketchup_promotion',
  CHRISTMAS_2024 = 'christmas_2024',
  NEW_YEAR_2025_PRE = 'new_year_2025_pre',
  NEW_YEAR_2025_CASH_BONUS_EVENT = 'new_year_2025_cash_bonus_event',
  NEW_YEAR_2025_PERIOD_1 = 'new_year_2025_period_1',
  NEW_YEAR_2025_PERIOD_2 = 'new_year_2025_period_2',
  NEW_YEAR_2025_PERIOD_3 = 'new_year_2025_period_3',
}
export type TEVENTS_PERIOD = {
  [key in EVENTS]: {
    since: Dayjs;
    until: Dayjs;
  };
};
export type TSetterKeys = `set${Capitalize<string & keyof IOnGoingEventState>}`;

export interface IOnGoingEventState {
  onGoingEvents: EVENTS[];
}
export interface IOnGoingEventSetState {
  setOnGoingEvents: (onGoingEvents: EVENTS[]) => void;
}
