import { IS_PROD } from 'src/constants';

export const GENRE_CATEGORY_IDS = IS_PROD ? [390, 389, 378, 387, 388, 391] : [396, 395, 392, 393, 394, 397];
export const GENRE_CATEGORY_ID_TO_NAME_MAP = {
  [GENRE_CATEGORY_IDS[0]]: 'modern',
  [GENRE_CATEGORY_IDS[1]]: 'oriental',
  [GENRE_CATEGORY_IDS[2]]: 'romance',
  [GENRE_CATEGORY_IDS[3]]: 'action',
  [GENRE_CATEGORY_IDS[4]]: 'sf',
  [GENRE_CATEGORY_IDS[5]]: 'etc',
};

export const GENRE_CATEGORY_ID_TO_MIXPANEL_EVENT_NAME = {
  [GENRE_CATEGORY_IDS[0]]: 'Modern / School Life',
  [GENRE_CATEGORY_IDS[1]]: 'Oriental / Martial Arts',
  [GENRE_CATEGORY_IDS[2]]: 'Romance / Fantasy',
  [GENRE_CATEGORY_IDS[3]]: 'Action / Noir',
  [GENRE_CATEGORY_IDS[4]]: 'SF / Apocalypse',
  [GENRE_CATEGORY_IDS[5]]: 'All-Genre',
};

/**
 * - 개발, QA 시에는 recommendedProductsCategoryIds가 임시로 반영되어 있음
 */
export const GENRE = {
  // 현대・학원물
  [GENRE_CATEGORY_IDS[0]]: {
    title: 'genre:byName.modern.title',
    description: 'genre:byName.modern.description',
    colorStart: '#4EC1FF',
    colorEnd: '#4E4EFF',
    recommendedProductsCategoryIds: IS_PROD ? [401, 402, 403] : [398, 399, 400],
    recommendedProductsBackgroundImage: {
      desktop: 'genre/modern_pc_banner.png',
      smallTablet: 'genre/modern_ta_banner.png',
      mobile: 'genre/modern_mo_banner.png',
    },
  },
  // 동양・무협
  [GENRE_CATEGORY_IDS[1]]: {
    title: 'genre:byName.oriental.title',
    description: 'genre:byName.oriental.description',
    colorStart: '#890C79',
    colorEnd: '#428AFF',
    recommendedProductsCategoryIds: IS_PROD ? [413, 414, 415] : [398, 399, 400],
    recommendedProductsBackgroundImage: {
      desktop: 'genre/oriental_pc_banner.png',
      smallTablet: 'genre/oriental_ta_banner.png',
      mobile: 'genre/oriental_mo_banner.png',
    },
  },
  // 판타지・로맨스
  [GENRE_CATEGORY_IDS[2]]: {
    title: 'genre:byName.romance.title',
    description: 'genre:byName.romance.description',
    colorStart: '#E779D5',
    colorEnd: '#4EC1FF',
    recommendedProductsCategoryIds: IS_PROD ? [404, 405, 406] : [398, 399, 400],
    recommendedProductsBackgroundImage: {
      desktop: 'genre/romance_pc_banner.png',
      smallTablet: 'genre/romance_ta_banner.png',
      mobile: 'genre/romance_mo_banner.png',
    },
  },
  // 액션・느와르
  [GENRE_CATEGORY_IDS[3]]: {
    title: 'genre:byName.action.title',
    description: 'genre:byName.action.description',
    colorStart: '#FF7142',
    colorEnd: '#3E0C89',
    recommendedProductsCategoryIds: IS_PROD ? [407, 408, 409] : [398, 399, 400],
    recommendedProductsBackgroundImage: {
      desktop: 'genre/action_pc_banner.png',
      smallTablet: 'genre/action_ta_banner.png',
      mobile: 'genre/action_mo_banner.png',
    },
  },
  // SF・아포칼립스
  [GENRE_CATEGORY_IDS[4]]: {
    title: 'genre:byName.sf.title',
    description: 'genre:byName.sf.description',
    colorStart: '#0EBC3D',
    colorEnd: '#380B5B',
    recommendedProductsCategoryIds: IS_PROD ? [410, 411, 412] : [398, 399, 400],
    recommendedProductsBackgroundImage: {
      desktop: 'genre/sf_pc_banner.png',
      smallTablet: 'genre/sf_ta_banner.png',
      mobile: 'genre/sf_mo_banner.png',
    },
  },
  // 장르무관
  [GENRE_CATEGORY_IDS[5]]: {
    title: 'genre:byName.etc.title',
    description: 'genre:byName.etc.description',
    colorStart: '#1F1F1F',
    colorEnd: '#3EC5D7 ',
    recommendedProductsCategoryIds: IS_PROD ? [416, 420, 418] : [398, 399, 400],
    recommendedProductsBackgroundImage: {
      desktop: 'genre/etc_pc_banner.png',
      smallTablet: 'genre/etc_ta_banner.png',
      mobile: 'genre/etc_mo_banner.png',
    },
  },
};

export const GENRE_RECOMMENDED_PRODUCTS_BACKGROUND_IMAGE_SIZE = {
  desktop: {
    width: 1440,
    height: 230,
  },
  smallTablet: {
    width: 600,
    height: 200,
  },
  mobile: {
    width: 360,
    height: 160,
  },
};
