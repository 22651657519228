import React, { useState, useMemo, useEffect } from 'react';
import { DehydratedState } from 'react-query/types/core/hydration';
import { GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { dehydrate, useQuery } from 'react-query';
import { SSRConfig, useTranslation } from 'next-i18next';
import { serverSideTranslations } from '@util/i18nUtils';
import ScrapProducts from '@components/_new_components/Pages/main/scrapProducts/ScrapProducts';
import Page from '@components/Page';
import { Layout } from '@components/layout/Layout';
import MainBanners from '@components/_new_components/Pages/main/banner/MainBanners';
import { MainContents, MainPromotionAndInfoSectionWrapper } from '@components/_new_components/Pages/main/main.styles';
import { NoticesAndGuides, PopularBrands, PromotionBanner, RecentPurchases } from 'components/_new_components/Pages/main';
import { initializeApollo } from '@lib';
import { catchableQueryFactory } from '@lib/apolloClient';
import { captureException } from '@util/sentry';
import MainWidget from '@components/_new_components/Pages/main/MainWidget/MainWidget';
import { CategoryCodes } from '@components/_new_components/Common/layout/Category/Category.constants';
import { reactQueryClient } from '@lib/reactQuery/reactQueryClient';
import { targetBanner, typeBanner } from '@api/banner/banner';
import { IMiddleDetail, TLanguageCode } from '@api/banner/banner.types';
import { PROMOTION_BANNER_LIMIT } from 'src/constants/common.constants';
import { ACON_MAIN_SLIDE_AREA, ACON_MAIN_SLIDE_AREA_ENUM } from '@components/_new_components/Pages/main/MainWidget/MainWidget.constants';
import { clearViewedProductsLog } from '@util/viewed-products-log';
import { defaultNamespaces } from '../src/constants';
import {
  Categories_Input_Sort_Criterion,
  Categories_Input_Sort_Key,
  CategoriesV2Document,
  CategoriesV2QueryVariables,
  DisplayAssetWithItemsDocument,
  DisplayBrandWithItemsDocument,
  DisplayBrandWithItemsQueryVariables,
  Language_Code,
  Ordered_Products_Input_Condition_Order_State,
  Ordered_Products_Input_Sort_Criterion,
  Ordered_Products_Input_Sort_Key,
  OrderedProductsDocument,
  OrderedProductsQueryVariables,
  Products_Sort_Criterion,
  Products_Sort_Key,
  ProductsDocument,
  ProductsQueryVariables,
  Promotion_Concept_Codes,
  useProductsQuery,
} from '../src/generated/graphql';

MainPage.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout options={{ isHideSideCategory: false }}>{page}</Layout>;
};

export default function MainPage({ randomPagesOpenrun, randomPagesFrashSale, currentDateString, dailyDateString, locale }: InferGetStaticPropsType<typeof getStaticProps>) {
  const { i18n } = useTranslation();
  const language = (locale === 'acon' ? 'en' : locale) as TLanguageCode;
  const { data: bannerData, isLoading, error } = useQuery(['targetBanner', 'main', language], () => targetBanner('main', language), { staleTime: 1000 * 60 });
  const [isReadyFlashSale, setIsReadyFlashSale] = useState(false);

  // SSG에서 일시적으로 서버가 불안정할 경우 스켈레톤으로 보이는 걸 막기위해 클라에서 재조회
  const { data: productsDataOpenrun } = useProductsQuery({
    variables: {
      language: i18n.language?.toUpperCase() as Language_Code,
      pagination: {
        page: 1,
        limit: 1,
      },
      condition: {
        isPromoted: true,
        isMatureContent: false,
        salePriceAbove: 1,
        promotionConceptCode: Promotion_Concept_Codes.Openrun,
      },
      sort: {
        key: Products_Sort_Key.Id,
        criterion: Products_Sort_Criterion.Asc,
      },
    },
    skip: randomPagesOpenrun.length > 0,
    onCompleted: (data) => {
      // server오류 및 오픈런 상품이 없어서 조회가 스킵되지않고 조회가 되었을 경우 전체 상품개수가 5개 미만인지 확인
      const totalCnt = data?.products?.pagination?.totalCount || 0;
      // 5개 미만이라면 반짝할인 상품을 랜덤으로 조회하기 위해 flag를 true로 변경
      if (totalCnt < PROMOTION_BANNER_LIMIT) setIsReadyFlashSale(true);
    },
  });
  const productsTotalCountOpenrun = productsDataOpenrun?.products?.pagination?.totalCount || 0;

  // 이후 오픈런 상품개수가 5개 미만일 경우, 반짝할인 상품을 랜덤으로 조회
  const { data: productsDataFrashSale } = useProductsQuery({
    variables: {
      language: i18n.language?.toUpperCase() as Language_Code,
      pagination: {
        page: 1,
        limit: 1,
      },
      condition: {
        isPromoted: true,
        isMatureContent: false,
        salePriceAbove: 1,
        promotionConceptCode: Promotion_Concept_Codes.Flashsale,
      },
      sort: {
        key: Products_Sort_Key.Id,
        criterion: Products_Sort_Criterion.Asc,
      },
    },
    // 스킵조건
    // ssg로 조회한 randomPagesFrashSale 개수가 있다.
    // ssg로 조회한 오픈런 상품개수가 5개 이상이다.
    // 클라에서 조회한 오픈런 상품개수가 5개 이상 이여서 flag가 false이다.
    skip: randomPagesFrashSale.length > 0 || randomPagesOpenrun.length >= 5 || !isReadyFlashSale,
  });
  const productsTotalCountFrashSale = productsDataFrashSale?.products?.pagination?.totalCount || 0;

  const noticesAndGuidesRandomPagesOpenrun = useMemo(() => {
    if (randomPagesOpenrun.length > 0) return randomPagesOpenrun;
    if (!productsTotalCountOpenrun) return [];

    const result = [];
    const limit = PROMOTION_BANNER_LIMIT;
    function loop() {
      if (result.length === limit || (productsTotalCountOpenrun <= limit && result.length === productsTotalCountOpenrun)) return;
      const randomPage = Math.floor(Math.random() * productsTotalCountOpenrun) + 1;
      if (!result.includes(randomPage)) result.push(randomPage);
      loop();
    }

    loop();
    return result;
  }, [productsTotalCountFrashSale]);

  const noticesAndGuidesRandomPagesFrashSale = useMemo(() => {
    if (randomPagesFrashSale.length > 0) return randomPagesFrashSale;
    if (!productsTotalCountFrashSale) return [];

    const result = [];
    const limit = PROMOTION_BANNER_LIMIT - Number(isReadyFlashSale ? noticesAndGuidesRandomPagesOpenrun.length : randomPagesOpenrun.length);

    function loop() {
      if (result.length === limit || (productsTotalCountFrashSale <= limit && result.length === productsTotalCountFrashSale)) return;
      const randomPage = Math.floor(Math.random() * productsTotalCountFrashSale) + 1;
      if (!result.includes(randomPage)) result.push(randomPage);
      loop();
    }

    loop();
    return result;
  }, [noticesAndGuidesRandomPagesOpenrun, productsTotalCountFrashSale]);

  useEffect(() => clearViewedProductsLog(), []);

  return (
    <Page>
      <MainContents>
        {/** 메인, 서브 슬라이드 배너 (고정) */}
        <MainBanners data={bannerData} isLoading={Boolean(isLoading || error)} />
        {/** 할인, 인기작가, 실시간 구매, 띠배너 영역 (고정) */}
        <MainPromotionAndInfoSectionWrapper>
          <PromotionBanner randomPagesOpenrun={noticesAndGuidesRandomPagesOpenrun} randomPagesFlashSale={noticesAndGuidesRandomPagesFrashSale} />
          <RecentPurchases />
          <ScrapProducts />
          <NoticesAndGuides data={bannerData?.middle as Array<IMiddleDetail>} isLoading={isLoading} />
          <PopularBrands />
        </MainPromotionAndInfoSectionWrapper>

        {/** 수동구좌, 자동구좌 영역 */}
        <MainWidget currentDateString={currentDateString} dailyDateString={dailyDateString} />
      </MainContents>
    </Page>
  );
}
export const getStaticProps = (async ({ locale }: GetStaticPropsContext) => {
  const apolloClient = initializeApollo();
  const catchableQuery = catchableQueryFactory(apolloClient);
  let randomPagesOpenrun = [];
  let randomPagesFrashSale = [];
  let totalCountOpenrun = 0;
  let totalCountFrashsale = 0;

  const currentDate = (() => {
    const date = new Date();
    date.setUTCHours(-9, 0, 0, 0);
    return date;
  })();
  const dailyDateObj = (() => {
    const date = new Date(currentDate);
    date.setUTCDate(date.getUTCDate() - 1);
    return date;
  })();

  if (locale !== 'acon') {
    const language = locale.toUpperCase() as Language_Code;
    const filterMainWidgetArea = Object.entries(ACON_MAIN_SLIDE_AREA)
      .map((_, index) => ACON_MAIN_SLIDE_AREA[index][locale])
      .filter((type) => type)
      .filter((type) => {
        if (type === ACON_MAIN_SLIDE_AREA_ENUM.DAILY_BEST) return false;
        if (type === ACON_MAIN_SLIDE_AREA_ENUM.MONTHLY_BEST) return false;
        return true;
      });

    try {
      // 지금 놓치면 가격이 올라가요 랜덤 상품 조회를 위한 totalCount 조회
      const promotionBannerOpenrun = await catchableQuery<ProductsQueryVariables>({
        query: ProductsDocument,
        variables: {
          language: language,
          pagination: {
            page: 1,
            limit: 1,
          },
          condition: {
            isPromoted: true,
            isMatureContent: false,
            salePriceAbove: 1,
            promotionConceptCode: Promotion_Concept_Codes.Openrun,
          },
          sort: {
            key: Products_Sort_Key.Id,
            criterion: Products_Sort_Criterion.Asc,
          },
        },
      });
      totalCountOpenrun = Number(promotionBannerOpenrun?.data?.products?.pagination?.totalCount || '0');
      randomPagesOpenrun = (() => {
        if (!totalCountOpenrun) return [];
        const result = [];
        const limit = PROMOTION_BANNER_LIMIT;
        function loop() {
          if (result.length === limit || (totalCountOpenrun <= limit && result.length === totalCountOpenrun)) return;
          const randomPage = Math.floor(Math.random() * totalCountOpenrun) + 1;
          if (!result.includes(randomPage)) result.push(randomPage);
          loop();
        }

        loop();
        return result;
      })();

      if (totalCountOpenrun < PROMOTION_BANNER_LIMIT) {
        const promotionBannerFrashSale = await catchableQuery<ProductsQueryVariables>({
          query: ProductsDocument,
          variables: {
            language: language,
            pagination: {
              page: 1,
              limit: 1,
            },
            condition: {
              isPromoted: true,
              isMatureContent: false,
              salePriceAbove: 1,
              promotionConceptCode: Promotion_Concept_Codes.Flashsale,
            },
            sort: {
              key: Products_Sort_Key.Id,
              criterion: Products_Sort_Criterion.Asc,
            },
          },
        });
        totalCountFrashsale = Number(promotionBannerFrashSale?.data?.products?.pagination?.totalCount || '0');
        randomPagesFrashSale = (() => {
          if (!totalCountFrashsale) return [];
          const result = [];
          const limit = PROMOTION_BANNER_LIMIT - totalCountOpenrun;
          function loop() {
            if (result.length === limit || (totalCountFrashsale <= limit && result.length === totalCountFrashsale)) return;
            const randomPage = Math.floor(Math.random() * totalCountFrashsale) + 1;
            if (!result.includes(randomPage)) result.push(randomPage);
            loop();
          }

          loop();
          return result;
        })();
      }

      const promiseArray = [
        reactQueryClient.prefetchQuery(['targetBanner', 'main', locale], () => targetBanner('main', locale as TLanguageCode), { staleTime: 1000 * 30 }),
        reactQueryClient.prefetchQuery(['typeBanner', 'band', locale], () => typeBanner('band', locale as TLanguageCode), { staleTime: 1000 * 30 }),
        // 지금 놓치면 가격이 올라가요 5개 랜덤 상품 조회
        randomPagesOpenrun.map((page) =>
          catchableQuery<ProductsQueryVariables>({
            query: ProductsDocument,
            variables: {
              language: locale.toUpperCase() as Language_Code,
              pagination: {
                page: page,
                limit: 1,
              },
              condition: {
                isPromoted: true,
                isMatureContent: false,
                salePriceAbove: 1,
                promotionConceptCode: Promotion_Concept_Codes.Openrun,
              },
              sort: {
                key: Products_Sort_Key.Id,
                criterion: Products_Sort_Criterion.Asc,
              },
            },
          }),
        ),
        // 후속으로 오픈런 상품개수가 5개 미만일 경우, 반짝할인 상품을 랜덤으로 조회
        randomPagesFrashSale.map((page) =>
          catchableQuery<ProductsQueryVariables>({
            query: ProductsDocument,
            variables: {
              language: locale.toUpperCase() as Language_Code,
              pagination: {
                page: page,
                limit: 1,
              },
              condition: {
                isPromoted: true,
                isMatureContent: false,
                salePriceAbove: 1,
                promotionConceptCode: Promotion_Concept_Codes.Flashsale,
              },
              sort: {
                key: Products_Sort_Key.Id,
                criterion: Products_Sort_Criterion.Asc,
              },
            },
          }),
        ),

        // ACON 베스트 브랜드
        catchableQuery<DisplayBrandWithItemsQueryVariables>({
          query: DisplayBrandWithItemsDocument,
          variables: {
            language: language,
            pagination: { page: 1, limit: 50 },
            type: 'POPULAR',
          },
        }),

        // 카테고리
        catchableQuery<CategoriesV2QueryVariables>({
          query: CategoriesV2Document,
          variables: {
            language: language,
            condition: {
              visibleOnly: true,
              codes: CategoryCodes,
            },
            sort: {
              key: Categories_Input_Sort_Key.Code,
              criterion: Categories_Input_Sort_Criterion.Asc,
            },
          },
        }),

        // (02.27) 메인 수동 구좌 영역 리툴로 관리
        filterMainWidgetArea.map((type) =>
          catchableQuery({
            query: DisplayAssetWithItemsDocument,
            variables: {
              language: language,
              pagination: { page: 1, limit: 20 },
              type: type,
            },
          }),
        ),

        // 일간 BEST 상품
        catchableQuery<OrderedProductsQueryVariables>({
          query: OrderedProductsDocument,
          variables: {
            language: language,
            pagination: {
              page: 1,
              limit: 20,
            },
            condition: {
              since: dailyDateObj.toISOString(),
              until: currentDate.toISOString(),
              orderStates: [Ordered_Products_Input_Condition_Order_State.Confirmed, Ordered_Products_Input_Condition_Order_State.Settled],
              isMatureContent: false,
              isOnSale: true,
              salePriceAbove: 1,
            },
            sort: {
              key: Ordered_Products_Input_Sort_Key.OrderCount,
              criterion: Ordered_Products_Input_Sort_Criterion.Desc,
            },
          },
        }),

        // 올타임 베스트 상품
        catchableQuery<ProductsQueryVariables>({
          query: ProductsDocument,
          variables: {
            language: language,
            pagination: {
              page: 1,
              limit: 20,
            },
            sort: {
              key: Products_Sort_Key.OrderCount,
              criterion: Products_Sort_Criterion.Desc,
            },
            condition: {
              isMatureContent: false,
              salePriceAbove: 1,
            },
          },
        }),
      ];

      await Promise.all(promiseArray);
    } catch (error) {
      // ApolloError expected
      captureException(error, { extra: { route: '/' } });
    }
  }

  return {
    props: {
      randomPagesOpenrun: randomPagesOpenrun,
      randomPagesFrashSale: randomPagesFrashSale,
      currentDateString: currentDate.toISOString(),
      dailyDateString: dailyDateObj.toISOString(),
      initialApolloState: apolloClient.cache.extract(),
      locale,
      dehydratedState: dehydrate(reactQueryClient),
      ...(await serverSideTranslations(locale === 'acon' ? 'en' : locale, [
        ...defaultNamespaces,
        'event',
        'product',
        'brand',
        'mainCategory',
        'category',
        'productItems',
        'category',
        'search',
        'new',
        'game',
        'main',
      ])),
    },
    revalidate: 60,
  };
}) satisfies GetStaticProps<
  {
    dehydratedState: DehydratedState;
    randomPagesOpenrun: number[];
    randomPagesFrashSale: number[];
    currentDateString: string;
    dailyDateString: string;
  } & SSRConfig
>;
