export const GA_ACON_USER_ID_COOKIE_KEY = 'ga_acon_user_id';

export const SUBGNB_TAB_NAME_MAP = {
  gnb__event: 'event',
  gnb__best: 'best',
  gnb__new: 'new',
  gnb__hotoffer: 'comet-deal',
  gnb__cosmic: 'cosmic-deal',
  gnb__photoreviews: 'photoreviews',
  gnb__abler: 'abler',
  gnb__adult: 'adult',
  gnb__sale: 'sale',
  gnb__funding: 'funding',
  gnb__community: 'community',
  gnb__aconpick: 'best', // ACON PICK 클릭 시, best로 이동되므로 log도 best로 심기로 함
  gnb__category: 'category',
  gnb__aconOnly: 'acon-only',
  gnb__genre: 'genre',
};
