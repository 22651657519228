import { SUB_GNB_MENU_LIST } from '@components/_new_components/Common/layout/Header/SubGnb/SubGnb.types';
import { Industry } from '../../../../../../src/constants/industry.constants';
import { BRAND_CODES } from '../../../../../../src/constants/brand/brand.constants';

const SUB_GNB_DEFAULT_MENU_LIST: SUB_GNB_MENU_LIST[] = [
  {
    key: 'aconOnly',
    href: '/acon-only',
    tagging: 'gnb__aconOnly',
    activePath: {
      pathname: '/acon-only',
      query: null,
    },
  },
  {
    key: 'genre',
    href: '/genre',
    tagging: 'gnb__genre',
    activePath: {
      pathname: '/genre',
      query: null,
    },
  },
  {
    key: 'new',
    href: '/new',
    tagging: 'gnb__new',
    activePath: {
      pathname: '/new',
      query: null,
    },
  },
  {
    key: 'sale',
    href: '/event/sale',
    tagging: 'gnb__sale',
    activePath: {
      pathname: '/event/sale',
      query: null,
    },
  },
  {
    key: 'best',
    href: '/best',
    tagging: 'gnb__best',
    activePath: {
      pathname: '/best',
      query: null,
    },
  },
  {
    key: 'adult',
    href: '/adult',
    tagging: 'gnb__adult',
    activePath: {
      pathname: '/adult',
      query: null,
    },
  },
  {
    key: 'event',
    href: '/event',
    tagging: 'gnb__event',
    activePath: {
      pathname: '/event',
      query: null,
    },
  },
  {
    key: 'divider',
    href: '',
    tagging: '',
    activePath: null,
  },
  {
    key: 'photoreview',
    href: '/photoreviews',
    tagging: 'gnb__photoreview',
    activePath: {
      pathname: '/photoreviews',
      query: null,
    },
  },
  {
    key: 'community',
    href: '/community',
    tagging: 'gnb__community',
    activePath: {
      pathname: '/community',
      query: null,
    },
  },
  {
    key: 'ablur',
    href: '/order/ablur',
    tagging: 'gnb__ablur',
    activePath: {
      pathname: '/order/ablur',
      query: null,
    },
  },
];

const SUB_GNB_GAME_MENU_LIST: SUB_GNB_MENU_LIST[] = [
  {
    key: 'new',
    href: '/new',
    tagging: 'gnb__new',
    activePath: {
      pathname: '/new',
      query: null,
    },
  },
  {
    key: 'resourceBank',
    href: `/brand/${BRAND_CODES.RESOURCE_BANK}`,
    tagging: 'gnb__resource_bank',
    activePath: {
      pathname: '/brand/[id]',
      query: { id: BRAND_CODES.RESOURCE_BANK },
    },
  },
  {
    key: 'divider',
    href: '',
    tagging: '',
    activePath: null,
  },
  {
    key: 'community',
    href: '/community',
    tagging: 'gnb__community',
    activePath: {
      pathname: '/community',
      query: null,
    },
  },
];

export const MENU_LIST_BY_INDUSTRY = {
  [Industry.ALL]: SUB_GNB_DEFAULT_MENU_LIST,
  [Industry.GAME]: SUB_GNB_GAME_MENU_LIST,
};
