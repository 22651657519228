import React from 'react';
import dayjs from 'dayjs';
import Link from 'next/link';
import { discountAmount } from '@lib/price';
import {
  ProductCardBadgeWrapper,
  ProductCardContainer,
  ProductCardDiscountRateText,
  ProductCardFreeText,
  ProductCardImageAbsolute,
  ProductCardImageLink,
  ProductCardImageWrapper,
  ProductCardInnerBorder,
  ProductCardPriceText,
  ProductCardPriceWrapper,
  ProductCardRemainingTimer,
  ProductCardTitleText,
} from '@components/_new_components/Common/ProductCard/ProductCard.styles';
import { CustomImage } from '@components/ui/Image';
import Tags from '@components/_new_components/Common/ProductCard/Tag/Tags';
import { scrapLog } from '@lib/mixpanel/events';
import { TActionName } from '@components/common/CardGrid/type';
import Extensions from '@components/_new_components/Common/ProductCard/Extensions/Extensions';
import ProductCardBrand from '@components/_new_components/Common/ProductCard/brand/ProductCardBrand';
import { ProductCardBrandPropsType } from '@components/_new_components/Common/ProductCard/brand/ProductCardBrand.types';
import { TProduct } from '../../../../src/types/product.types';
import { ProductOutput, TagOutput } from '../../../../src/generated/graphql';
import NewBadgeIcon from '../../../../src/assets/icons/productCard/badge/NewBadgeIcon';
import ReviewAndStar from './ReviewAndStar/ReviewAndStar';

interface Props {
  product?: TProduct;
  productCardImageSizes?: string;
  onClickProductCard?: (actionName: TActionName, title: string, productId: number) => void;
  render?: {
    brand?: (brand: ProductCardBrandPropsType) => React.ReactNode;
    extensions?: (extensions: ProductOutput['extensions']) => React.ReactNode;
    tags?: (tag: TagOutput[]) => React.ReactNode;
    reviewAndStar?: (viewCount: number, review: TProduct['reviewInfo']['review']) => React.ReactNode;
  };
  className?: string;
}

export default function ProductCard({ product, productCardImageSizes, onClickProductCard, render, className }: Props) {
  const [remainingTime, setRemainingTime] = React.useState(null);
  const isNewProduct = dayjs(product?.dateInfo?.releasedAt || '').isAfter(dayjs().subtract(30, 'days'));

  const productDiscountRate = discountAmount(product.priceInfo.price, product.priceInfo.salePrice);
  const imageSizes =
    productCardImageSizes ||
    '(max-width: 599px) calc(100vw - 34px), (max-width: 835px) calc(50vw - 34px), (max-width: 1199px) calc(50vw - 88px), (max-width: 1444px) calc(25vw - 57px), 304px';

  const handleScrapClick = () => {
    scrapLog({ productNos: [Number(product.id)], productStatus: [...(isNewProduct ? ['NEW'] : [])], type: 'product card' });
  };

  const handleCardClick = (actionName: TActionName) => {
    return () => onClickProductCard && onClickProductCard(actionName, product.title, product.id);
  };

  React.useEffect(() => {
    if (!product || !product?.promotionInfo?.until) return;
    setRemainingTime(new Date(product.promotionInfo.until));
  }, [product]);

  return (
    <ProductCardContainer className={className}>
      <ProductCardImageLink href={`/product/${product.id}`} prefetch={false}>
        <ProductCardImageWrapper onClick={handleCardClick('image')}>
          <ProductCardBadgeWrapper>
            {isNewProduct && <NewBadgeIcon />}
            {remainingTime && <ProductCardRemainingTimer remainingTime={remainingTime} />}
          </ProductCardBadgeWrapper>

          {/** main image */}
          <ProductCardImageAbsolute className={'main'}>
            <CustomImage src={product.imageInfo.main.url} alt={product.title} quality={60} style={{ objectFit: 'cover' }} priority={false} sizes={imageSizes} />
          </ProductCardImageAbsolute>

          {/** sub image */}
          <ProductCardImageAbsolute className={'sub'}>
            <CustomImage src={product.imageInfo.sub.url} alt={product.title} quality={60} style={{ objectFit: 'cover' }} priority={false} sizes={imageSizes} />
          </ProductCardImageAbsolute>

          <ProductCardInnerBorder />
        </ProductCardImageWrapper>
      </ProductCardImageLink>
      {/** 상품 브랜드 및 에이콘 온리 */}
      {render?.brand ? (
        render.brand({ id: product.id, brandInfo: product.brandInfo, onClickBrand: handleCardClick('brand'), onClickScrap: handleScrapClick })
      ) : (
        <ProductCardBrand id={product.id} brandInfo={product.brandInfo} onClickBrand={handleCardClick('brand')} onClickScrap={handleScrapClick} />
      )}
      {/** 상품 제목 */}
      <Link href={`/product/${product.id}`} onClick={handleCardClick('title')} prefetch={false}>
        <ProductCardTitleText>{product.title}</ProductCardTitleText>
      </Link>
      <ProductCardPriceWrapper>
        {product.priceInfo.price > 0 ? (
          <>
            {productDiscountRate > 0 && <ProductCardDiscountRateText>{productDiscountRate}%</ProductCardDiscountRateText>}
            <ProductCardPriceText amount={product.priceInfo.salePrice} />
          </>
        ) : (
          <ProductCardFreeText>FREE</ProductCardFreeText>
        )}
      </ProductCardPriceWrapper>
      {render?.extensions ? render.extensions(product.extensions) : <Extensions productId={product.id} extensions={product.extensions} />}
      {render?.tags ? render.tags(product.tags) : <Tags tags={product.tags.map((tag) => tag.name)} tagIds={product.tags.map((tag) => tag.id)} />}
      {render?.reviewAndStar ? (
        render.reviewAndStar(product?.produkt?.viewCount || 0, product?.reviewInfo?.review)
      ) : (
        <ReviewAndStar viewCount={product?.produkt?.viewCount || 0} review={product?.reviewInfo?.review} />
      )}
    </ProductCardContainer>
  );
}
