import React from 'react';

export default function AconGrayLogo({ isJapanese }: { isJapanese?: boolean }) {
  if (isJapanese) {
    return (
      <svg width="71" height="16" viewBox="0 0 71 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2556 7.94196C15.2556 3.83372 18.5859 0.503357 22.6941 0.503357H26.4135V5.46243L22.9728 4.99325C21.1859 4.74958 19.5947 6.13854 19.5947 7.94196C19.5947 9.74543 21.1859 11.1343 22.9728 10.8907L26.4135 10.4215V15.3805H22.6941C18.5859 15.3805 15.2556 12.0502 15.2556 7.94196ZM11.8464 0.503366H3.7879L0.37854 15.3805H5.02766L5.3376 13.5209H10.2967L10.6066 15.3806L15.2558 15.3805L11.8464 0.503366ZM8.85027 4.84255H6.78402L5.90583 10.1115H9.72841L8.85027 4.84255ZM27.6531 7.63203C27.6531 3.69496 30.8447 0.503357 34.7817 0.503357C38.7188 0.503357 41.9104 3.69496 41.9104 7.63203V8.25194C41.9104 12.189 38.7188 15.3806 34.7817 15.3806C30.8447 15.3806 27.6531 12.189 27.6531 8.25194V7.63203ZM31.9923 7.63203C31.9923 6.0914 33.2411 4.84254 34.7817 4.84254C36.3223 4.84254 37.5712 6.0914 37.5712 7.63203V8.25194C37.5712 9.79245 36.3223 11.0414 34.7817 11.0414C33.2411 11.0414 31.9923 9.79245 31.9923 8.25194V7.63203ZM43.1505 15.3806V0.503357H47.1798L52.0184 8.51154L51.9848 7.98858H51.9867L51.5188 0.503357H56.1679L56.1674 9.42858H48.5446L48.3717 9.14287H48.3702L47.2989 7.36977L47.4455 9.71429V15.3806H43.1505ZM58.4869 10.2857C57.4237 11.3428 55.4409 12.3144 53.8797 12.5466L54.0446 13.4937C54.7137 13.3898 55.4531 13.1699 56.1772 12.8674V15.4857H57.2893V12.3358C58.0012 11.9478 58.6428 11.4986 59.1224 11.0373L58.4869 10.2857ZM65.5536 14.3003C67.8603 14.0742 68.9632 13.1943 69.3971 11.2267L70.3748 11.4406C69.9348 13.8451 68.4561 15.061 65.7125 15.3024L65.5536 14.3003ZM65.3214 11.4528L65.8102 10.5301C66.516 10.7654 67.1973 11.0679 67.8695 11.4284L67.3501 12.3449C66.7054 11.9967 66.0149 11.6972 65.3214 11.4528ZM63.2715 15.4185V15.0091H59.6358V14.0803H63.2715V11.5689H59.6358V10.6462H64.3531V15.4185H63.2715ZM48.7502 15.1252V14.1964H50.6689V11.6239H48.8724V10.7074H53.5408V11.6239H51.7504V14.1964H53.663V15.1252H48.7502Z"
          fill="#6D6D70"
        />
      </svg>
    );
  }

  return (
    <svg width="60" height="16" viewBox="0 0 60 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66667 0H12.3333L16 16L11 16L10.6667 14H5.33333L5 16H0L3.66667 0ZM6.88889 4.66667H9.11111L10.0556 10.3333H5.94444L6.88889 4.66667Z"
        fill="#6D6D70"
      />
      <path
        d="M24 0C19.5817 0 16 3.58171 16 7.99998C16 12.4182 19.5817 16 24 16H28L28 10.6667L24.2997 11.1713C22.378 11.4333 20.6667 9.93952 20.6667 8C20.6667 6.06048 22.378 4.56669 24.2997 4.82875L28 5.33333L28 0H24Z"
        fill="#6D6D70"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37 0C32.7658 0 29.3333 3.43248 29.3333 7.66667V8.33333C29.3333 12.5675 32.7658 16 37 16C41.2342 16 44.6667 12.5675 44.6667 8.33333V7.66667C44.6667 3.43248 41.2342 0 37 0ZM37 4.66667C35.3431 4.66667 34 6.00981 34 7.66667V8.33333C34 9.99019 35.3431 11.3333 37 11.3333C38.6569 11.3333 40 9.99019 40 8.33333V7.66667C40 6.00981 38.6569 4.66667 37 4.66667Z"
        fill="#6D6D70"
      />
      <path d="M51 16H46V0H50.3333L55.5385 8.61539L55 0H60V16H55.6667L50.4615 7.38462L51 16Z" fill="#6D6D70" />
    </svg>
  );
}
